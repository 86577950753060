import React, { useContext } from "react";
import { Link, useParams } from "react-router-dom";
import { DataContext } from "./DataContext";
import Spinner from "./components/Spinner";
import Button from "./components/Button";
import Card from "./components/Card";

const Category = () => {
  let { category } = useParams();
  category = decodeURIComponent(category);

  const { data, loading, error } = useContext(DataContext);

  if (loading) return <Spinner />;
  if (error) return <div>Error: {error.message}</div>;
  const studies = data.filter((item) => item["Category"] === category);

  const descriptions = {
    "Healthy eating": "Discover insights into healthy eating habits and dietary impacts on adolescent health.",
    "Physical Activity": "Access research on the benefits of physical activity and effective interventions to promote active lifestyles among students.",
    "Toxic habits and screens": "Explore strategies to address harmful behaviors like substance abuse and excessive screen time among lescents.",
    "Mental health": "Learn about coping mechanisms and support systems to address mental health challenges among students.",
    "Sexual health": "Gain knowledge on sexual education and reproductive health to empower informed decision-making among adolescents.",
    "General well-being": "Explore holistic approaches to promote well-being and create supportive school environments for students' optimal health.",
  };

  return (
    <Card>
      <h1 className="text-xl md:text-2xl text-left">
        Filtered by category: <span className="font-bold text-nowrap">{category}</span>
      </h1>
        {category in descriptions && (
          <p>{descriptions[category]}</p>
        )}
      <ul className="flex flex-col gap-2 sm:gap-5">
        {studies
          .sort((a, b) => a["Study ID"] - b["Study ID"])
          .map((item) => (
            <li key={item["Study ID"]}>
              <Link to={`/study/${item["Study ID"]}`}>
                <Button>
                  <strong>Study {item["Study ID"]}:</strong> {item["Title"]}
                </Button>
              </Link>
            </li>
          ))}
      </ul>
      <div className="flex justify-left gap-2 sm:gap-5 items-center">
        <Link to="/">
          <Button>Home</Button>
        </Link>
        <span>›</span>
        <span>{category}</span>
      </div>
    </Card>
  );
};

export default Category;
