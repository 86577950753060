import React, { useContext } from "react";
import { Link } from "react-router-dom";
import { DataContext } from "./DataContext";
import Spinner from "./components/Spinner";
import Button from "./components/Button";
import Card from "./components/Card";

const Home = () => {
  const { data, loading, error } = useContext(DataContext);

  if (loading) return <Spinner />;
  if (error) return <div>Error: {error.message}</div>;
  const categories = [...new Set(data.map((item) => item["Category"]))];

  return (
    <Card>
      <h1 className="font-bold text-xl md:text-2xl text-left">
        Empowering Adolescent Healthy Lifestyles through
        Actionable Insights
      </h1>
      <div>
        <p>
          Adolescence is a pivotal stage where lifelong health habits take root,
          yet many teenagers are struggling to adopt healthy lifestyles. From
          insufficient exercise to risky behaviors, the challenges are
          significant. This is where schools play a crucial role. Schools are
          not merely educational institutions but also key players in promoting
          well-being.
        </p>
        <p className="pt-4">
          Our mission in the current project is to assist schools in the
          development and implementation of lifestyle interventions, and this is
          why we have created GRoW Healthier.
        </p>
        <p className="pt-4">
          GRoW Healthier is more than just a repository of findings; it's a
          dynamic platform designed to catalyze actionable change. In this
          platform each intervention analyzed in our review is meticulously
          documented in cards, providing stakeholders with concise yet
          comprehensive summaries.
        </p>
        <p className="pt-4">
          Join us in harnessing the power of evidence-based information to drive
          meaningful actions. Let's collaborate to create a brighter, healthier
          future for adolescents through GRoW Healthier.
        </p>
      </div>
      <div className="flex flex-col justify-center gap-3 md:gap-5">
        <h2 className="font-bold text-left text-l md:text-xl">
          Study categories:
        </h2>
        <div className="flex gap-2 sm:gap-5 justify-left flex-wrap">
          {categories.map((category) => (
            <Link key={category} to={`/${category}`}>
              <Button>{category}</Button>
            </Link>
          ))}
        </div>
      </div>
    </Card>
  );
};

export default Home;
