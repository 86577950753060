import React from 'react';
import {
  BrowserRouter as Router,
  Routes,
  Route
} from "react-router-dom";
import { DataProvider } from './DataContext';

import Home from './Home';
import Category from './Category';
import Study from './Study';

const App = () => {
  return (
    <DataProvider>
      <div className="flex flex-col min-h-screen p-2 md:p-5 overflow-hidden gap-2">
        <header className="flex flex-col justify-center items-center flex-1 mb-5">
          <Router>
            <Routes>
              <Route path="/" element={<Home />} />
              <Route path="/:category" element={<Category />} />
              <Route path="/study/:id" element={<Study />} />
            </Routes>
          </Router>
        </header>
        <footer className="m-3 text-center text-xs text-gray-500">
          <p>&copy; 2023 Global Research on Wellbeing (GRoW) <span className='text-nowrap'>research group - Facultat</span> de Ciències de la Salut Blanquerna, Universitat Ramon Llull amb el suport d'ARAMARK. <span className='text-nowrap'>Tots els drets reservats.</span></p>
        </footer>
      </div>
    </DataProvider>
  );
};

export default App;
