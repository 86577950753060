const Card = ({ children }) => {
  return (
    <div className="shadow-md transition-all duration-300 rounded-lg sm:rounded-xl md:rounded-2xl px-4 sm:px-6 py-8 md:p-14 bg-white  text-gray-800 max-w-full text-sm md:text-base">
      <div className="flex flex-col max-w-prose leading-relaxed gap-6 md:gap-10">
        <img src="/logo.png" alt="logo" className="self-center h-12 sm:h-14 md:h-16" />
        {children}
      </div>
    </div>
  );
};

export default Card;
