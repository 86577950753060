import React, { useContext } from "react";
import { useParams, Link } from "react-router-dom";
import { DataContext } from "./DataContext";
import Spinner from "./components/Spinner";
import Button from "./components/Button";
import Card from "./components/Card";

const Study = () => {
  let { id } = useParams();
  id = parseInt(id);

  const { data, loading, error } = useContext(DataContext);

  if (loading) return <Spinner />;
  if (error) return <div>Error: {error.message}</div>;
  const study = data.find((item) => item["Study ID"] === id);
  const studyLink = study["Link to access the publication of the manuscript"];
  const link =
    studyLink.startsWith("http://") || studyLink.startsWith("https://")
      ? studyLink
      : "https://" + studyLink;

  return (
    <Card>
      {study["Title"] != null && (
        <h1 className="font-bold text-xl md:text-2xl text-left">
          {study["Title"]}
        </h1>
      )}
      <div>
        {study["Study ID"] != null && (
          <p>
            <strong>Study ID:</strong> {study["Study ID"]}
          </p>
        )}
        {study["Country"] != null && (
          <p>
            <strong>Country:</strong> {study["Country"]}
          </p>
        )}
        {study["Year of intervention"] != null && study["Year of intervention"] !== 0 && (
          <p>
            <strong>Year of intervention:</strong>{" "}
            {study["Year of intervention"]}
          </p>
        )}
        {study["Year of publication"] != null && study["Year of publication"] !== 0 && (
          <p>
            <strong>Year of publication:</strong> {study["Year of publication"]}
          </p>
        )}
        {study["Link to access the publication of the manuscript"] != null && (
          <p className="flex gap-1">
            <strong>Manuscript:</strong>
            <a
              className="hover:underline text-blue-600 visited:text-purple-600 block truncate ..."
              href={link}
              target="_blank"
              rel="noopener noreferrer"
            >
              {link}
            </a>
          </p>
        )}
        <div className="my-3 pl-1.5 sm:pl-2 md:pl-3 border-l">
          {study["Age"] != null && (
            <p>
              <strong>Age:</strong> {study["Age"]}
            </p>
          )}
          {study["Gender"] != null && (
            <p>
              <strong>Gender:</strong> {study["Gender"]}
            </p>
          )}
          {study["Race"] != null && (
            <p>
              <strong>Race:</strong> {study["Race"]}
            </p>
          )}
          {study["Ethnicity"] != null && (
            <p>
              <strong>Ethnicity:</strong> {study["Ethnicity"]}
            </p>
          )}
        </div>
        {study["Characteristics of the school"] != null && (
          <p>
            <strong>Characteristics of the school:</strong>{" "}
            {study["Characteristics of the school"]}
          </p>
        )}
        {study["Objective of the intervention"] != null && (
          <p>
            <strong>Objective of the intervention:</strong>{" "}
            {study["Objective of the intervention"]}
          </p>
        )}
        <h5 className="font-bold mt-3">Description of the intervention</h5>
        <div className="my-3 pl-1.5 sm:pl-2 md:pl-3 border-l">
          {study["Number of sessions"] != null && (
            <p>
              <strong>Number of sessions:</strong> {study["Number of sessions"]}
            </p>
          )}
          {study["Total intervention time"] != null && (
            <p>
              <strong>Total intervention time:</strong>{" "}
              {study["Total intervention time"]}
            </p>
          )}
          {study["Topics covered"] != null && (
            <p>
              <strong>Topics covered:</strong> {study["Topics covered"]}
            </p>
          )}
          {study["Methodologies"] != null && (
            <p>
              <strong>Methodologies:</strong> {study["Methodologies"]}
            </p>
          )}
          {study["Models used"] != null && (
            <p>
              <strong>Models used:</strong> {study["Models used"]}
            </p>
          )}
          {study["Facilitators"] != null && (
            <p>
              <strong>Facilitators:</strong> {study["Facilitators"]}
            </p>
          )}
          {study["Sustainability"] != null && (
            <p>
              <strong>Sustainability:</strong> {study["Sustainability"]}
            </p>
          )}
        </div>
        {study["Results"] != null && (
          <p>
            <strong>Results:</strong> {study["Results"]}
          </p>
        )}
      </div>
      <div className="flex justify-left gap-2 sm:gap-5 items-center">
        <Link to="/">
          <Button>Home</Button>
        </Link>
        <span>›</span>
        <Link to={`/${study["Category"]}`}>
          <Button className="text-center">{study["Category"]}</Button>
        </Link>
        <span>›</span>
        <span className="text-nowrap">Study {study["Study ID"]}</span>
      </div>
    </Card>
  );
};

export default Study;
